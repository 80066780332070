<template>
  <div id="chartBar" class="bar-wrap"></div>
</template>
<script>
import echarts from 'echarts';
require('echarts/theme/macarons');
// import publicfuc from "../utils/publicMethod"
import api from '@/api';

export default {
  name: 'BarEcharts',
  props: {
    id: String,
    ctitle: String,

    stype: {
      type: String,
      default: 'line'
    },
    zbshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chartLine: null,
      colorData: [
        {
          itemStyle: {
            color: '#57c0f7'
          }
        },
        {
          itemStyle: {
            color: '#7be07a'
          }
        },
        {
          itemStyle: {
            color: '#b75ff1'
          }
        },
        {
          itemStyle: {
            color: '#f67b59'
          }
        },
        {
          itemStyle: {
            color: '#f1d40c'
          }
        },
        {
          itemStyle: {
            color: '#1d38df'
          }
        },
        {
          itemStyle: {
            color: '#ffafcc'
          }
        }
      ]
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.getNearlySevenDaysTransactionAmount();
    });
  },
  created() {
    // console.log(this.publicfuc.getDay(2));
  },
  methods: {
    drawLineChart() {
      const { barDataList, colorData } = this;
      barDataList.forEach((x, i) => {
        x['itemStyle'] = colorData[i].itemStyle;
      });
      let chartId = document.getElementById('chartBar');
      this.chartLine = echarts.init(chartId); // 基于准备好的dom，初始化echarts实例
      let option = {
        title: {
          text: '近七日交易额',
          textStyle: {
            color: '#333',
            fontSize: '22',
            fontWeight: '100'
          }
        },
        textStyle: {
          color: '#333'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: barDataList.map(x => {
              return x.date;
            }),
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#45495e'
              }
            }
          }
        ],
        series: [
          {
            name: '直接访问',
            type: 'bar',
            barWidth: '6',
            data: barDataList.map(x => {
              return {
                value: x.total,
                itemStyle: x.itemStyle
              };
            })
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表
      this.chartLine.setOption(option);
    },
    // 获取7日交易额
    async getNearlySevenDaysTransactionAmount() {
      const result = await api.common.nearlySevenDaysTransactionAmount();
      const data = result.data;
      data.forEach(x => {
        let arr = x.date.split('-');
        x.date = `${arr[1]}-${arr[2]}`;
      });
      this.barDataList = data;
      this.drawLineChart();
    }
  }
};
</script>

<style scoped>
.bar-wrap {
  width: 100%;
  height: 300px;
}
</style>